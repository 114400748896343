import React from 'react';

import Sidebar from './sidebar';

import { IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Close } from '@mui/icons-material';
import { useState } from 'react';
import { useEffect } from 'react';

interface Props {
  url: string;
  host: string;
  classes?: any;
  poi: any;
  phases: any;
  currentPhase: any;
  hasPhases: boolean;
  deleteContent: () => void;
  translations: any;
  projectName: string;
  sidebarWidth: number;
  isDrawerOpen: boolean;
  showFocuszones: boolean;
  toggleDrawer: () => void;
  closeDrawer: () => void;
  phaseSelected: (phaseId: number) => void;
  googleCookie: boolean;
  questionsStyledProps: any;
  openTour: () => void;
  openCookie: () => void;
  markerClicked: (poi: any, navigate: boolean) => void;
}

interface State {
  url: string;
  poi: any;
  currentPhase: any;
  phases: any;
  isDrawerOpen: boolean;
  description: string;
}

const useIconStyle = makeStyles({
  root: (props: any) => ({
    '&.MuiIconButton-root': {
      padding: '12px',
    },
    '&.MuiIconButton-colorPrimary': {
      backgroundColor: props.lighterAccent ? props.lighterAccent : 'lightgrey',
    },
    '&:hover, &.Mui-focusVisible': {
      backgroundColor: props.mainAccent ? props.mainAccent : 'grey',
    },
    width: '48px',
    top: '50px',
  }),
  phaseButtons: {
    marginLeft: '8px',
    top: '150px',
    zIndex: 1300,
  },
});

const useButtonStyle = makeStyles({
  root: {
    color: 'black',
  },
});

function Image(props: Props, state: State) {
  const iconClass = useIconStyle(props.questionsStyledProps);
  const buttonClass = useButtonStyle();

  const [url] = useState(props.host + '/' + props.url.split('/').pop());
  const [phases, setPhases] = useState(props.currentPhase);
  const [poi] = useState(props.poi);
  const [currentPhase, setCurrentPhase] = useState(props.currentPhase);
  const [isDrawerOpen, setIsDrawerOpen] = useState(props.isDrawerOpen);

  useEffect(() => {
    setPhases([]);
    let phasesWithPOIs : any = [];
    let POIsWithOtherPhase : Array<String> = [];
    if (poi.other_phases !== null && poi.other_phases !== undefined && poi.other_phases !== "") {
      POIsWithOtherPhase.push(poi.poi_id);
      
      poi.other_phases.split(',').forEach((phaseId: any) => {
        POIsWithOtherPhase.push(phaseId);
      });

      for (let phase of props.phases) {
        phase.POIS.forEach((phasePoi: any) => {
         if (POIsWithOtherPhase.includes(phasePoi.poi_id)) {
           phasesWithPOIs.push(phase);
         }
         if (phasePoi.poi_id === poi.poi_id) {
           setCurrentPhase(phase);
         }
        });
      }
      setPhases(phasesWithPOIs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPhaseSelected = (phaseId: number) => {
    if (phaseId === currentPhase.id) { return; }
    if (poi !== null || poi !== undefined) {
      const otherPhasesArray = poi.other_phases.split(',');
      props.phases.forEach((phase: any) => {
        if (phase.id === phaseId) {
          props.markerClicked(phase.POIS.find((poi: any) => otherPhasesArray.includes(poi.poi_id)), true);
        }
      })
    }
  };
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
    props.toggleDrawer();
  };

  const closeDrawer = () => {
    if (isDrawerOpen) {
      setIsDrawerOpen(false);
      props.closeDrawer();
    }
  };

  const showFocusZones = () => {
    return false;
  };

  return (
    <React.Fragment>
      <Sidebar
        isDrawerOpen={isDrawerOpen}
        isQuestion={false}
        closeDrawer={closeDrawer}
        toggleDrawer={toggleDrawer}
        description={poi.description}
        image={''}
        phases={phases}
        phaseSelected={onPhaseSelected}
        showFocuszones={props.showFocuszones}
        showFocuszonesCallback={showFocusZones}
        currentPhase={currentPhase}
        hasPhases={props.hasPhases}
        isMediaShown={true}
        translations={props.translations}
        projectName={props.projectName}
        sidebarWidth={props.sidebarWidth}
        googleCookie={props.googleCookie}
        questionsStyledProps={props.questionsStyledProps}
        openTour={props.openTour}
        openCookie={props.openCookie}
      />
      <div className="image-container" onClick={props.deleteContent}>
        <IconButton
          className={iconClass.root}
          color="primary"
          onClick={props.deleteContent}
        >
          <Close className={buttonClass.root} />
        </IconButton>
        <div className="image-box">
            <img src={url} alt="cookie" />
        </div>
      </div>
    </React.Fragment>
  );
}

export default Image;
