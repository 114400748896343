import { useMapEvents } from "react-leaflet"
import {useSearchParamsUpdater} from "../services/urlService";

export default function GetMapInfo() {
    const updateSearchParams = useSearchParamsUpdater();
    const map = useMapEvents({
        zoomend: () => {
            updateSearchParams({ zoom: map.getZoom().toString() });
        },
        moveend: () => {
            updateSearchParams({ lat: map.getCenter().lat.toString(), lng: map.getCenter().lng.toString() });
        }
    })

  
    return null;
}