import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Paper,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import { ExpandMore } from "@mui/icons-material";
import React, { useState } from "react";

interface Props {
  styledProps: any;
  handleSwitchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleButtonCookie: () => void;
  youtubeCookie: boolean;
  googleCookie: boolean;
}

const StyledPaper = withStyles({
  root: {
    flexDirection: "column",
    alignItems: "center !important",
    padding: "16px",
  },
})(Paper);

const useStyles = makeStyles({
  root: (props: any) => ({
    backgroundColor: props.mainAccent,
    color: props.mainAccentFontColor,
    transition: "filter 100ms",
    "&:hover": {
      backgroundColor: props.mainAccent,
      filter: "brightness(0.8)",
      transition: "filter 100ms",
    },
  }),
});

const useStylesSwitch = makeStyles({
  root: {
    width: 42,
    height: 28,
    padding: 0,
    margin: "4px",
  },
  switchBase: {
    padding: 1,
    transitionDuration: "200ms",
  },
  colorSecondary: (props: any) => ({
    "&$checked": {
      transform: "translateX(16px)",
      color: "white",
      "&$checked + $track": {
        backgroundColor: props.mainAccent,
        opacity: 1,
        border: "none",
        height: 26,
      },
    }
  }),
  disabled: () => ({
    "&$checked": {
      transform: "translateX(16px)",
      color: "white",
      "&$checked + $track": {
        backgroundColor: "grey",
        opacity: 1,
        border: "none",
        height: 26,
      },
    },
    "& .Mui-focusVisible": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  }),
  thumb: {
    width: 24,
    height: 24,
  },
  track: () => ({
    height: 24,
    borderRadius: 24 * 2,
    border: "1px solid #D9DADC",
    opacity: 1,
    backgroundColor: "white",
    transition: "background-color 100ms cubic-bezier(0, 0, 0, .1)",
  }),
  checked: () => ({}),
});

const useStylesToggle = makeStyles({
  root: (props: any) => ({
    "&$selected": {
      backgroundColor: props.mainAccent,
      color: props.mainAccentFontColor,
    },
    "&:hover": {
      backgroundColor: `${props.mainAccent} !important`,
      color: props.mainAccentFontColor,
      filter: "brightness(0.9)",
    },
  }),
  selected: () => ({}),
});

function CookieConsent(props: Props) {
  const buttonClass = useStyles(props.styledProps);
  const switchClass = useStylesSwitch(props.styledProps);
  const toggleClass = useStylesToggle(props.styledProps);

  const [lang, setLang] = useState("NL");
  const [header, setHeader] = useState("Cookie-instellingen");
  const [subHeader, setSubHeader] = useState(
    "UrbanWebMap gebruikt cookies om informatie over uw bezoek op te slaan en om uitgebreide functionaliteiten te bieden"
  );
  const [tableHeaderName, setTableHeaderName] = useState("Naam");
  const [tableHeaderExpires, setTableHeaderExpires] = useState("Verloopt");
  const [urbanWebMapCookieDescription, setUrbanWebMapCookieDescription] =
    useState(
      "Deze website gebruikt cookies om informatie op te slaan over uw bezoek en de cookies die u accepteert"
    );
  const [youtubeCookieDescription, setYoutubeCookieDescription] = useState(
    "De website kan youtube-videos bevatten. Als u deze cookie niet accepteert, worden er geen videos weergegeven"
  );
  const [googleCookieDescription, setGoogleCookieDescription] = useState(
    "De website kan ReCAPTCHA van www.google.com bevatten. Deze cookies zijn vereist voor e-mailverificatie. Als u deze cookie niet accepteert, kunt u geen vragen sturen"
  );

  const [requiredCookies, setRequiredCookies] = useState("Vereiste cookies");

  const [continueButton, setContinueButton] = useState("Opslaan");

  const [youtubeAccordionOpen, setYoutubeAccordionOpen] = useState(false);
  const [googleAccordionOpen, setGoogleAccordionOpen] = useState(false);
  const [requiredAccordionOpen, setRequiredAccordionOpen] = useState(false);

  const changeLanguage = (
    event: React.MouseEvent<HTMLButtonElement>,
    lang: string
  ) => {
    switch (lang) {
      case "EN":
        setLang(lang);
        setHeader("Cookie settings");
        setSubHeader(
          "UrbanWebMap uses cookies to store information about your visit and to provide extended functionalities"
        );
        setRequiredCookies("Required cookies");
        setTableHeaderName("Name");
        setTableHeaderExpires("Expires");
        setUrbanWebMapCookieDescription(
          "This website is using cookies to store information about your visit and the cookies you accept"
        );
        setYoutubeCookieDescription(
          "The website can include youtube videos. If you do not accept this cookie, videos will not be displayed"
        );
        setGoogleCookieDescription(
          "The website can include ReCAPTCHA from www.google.com. These cookies are required for email verification. If you do not accept this cookie, you will not be able to send questions"
        );
        setContinueButton("Save");
        break;
      case "NL":
        setLang(lang);
        setHeader("Cookie-instellingen");
        setSubHeader(
          "UrbanWebMap gebruikt cookies om informatie over uw bezoek op te slaan en om uitgebreide functionaliteiten te bieden"
        );
        setRequiredCookies("Vereiste cookies");
        setTableHeaderName("Naam");
        setTableHeaderExpires("Verloopt");
        setUrbanWebMapCookieDescription(
          "Deze website gebruikt cookies om informatie op te slaan over uw bezoek en de cookies die u accepteert"
        );
        setYoutubeCookieDescription(
          "De website kan youtube-videos bevatten. Als u deze cookie niet accepteert, worden er geen videos weergegeven"
        );
        setGoogleCookieDescription(
          "De website kan ReCAPTCHA van www.google.com bevatten. Deze cookies zijn vereist voor e-mailverificatie. Als u deze cookie niet accepteert, kunt u geen vragen sturen"
        );
        setContinueButton("Opslaan");
        break;
      case "FR":
        setLang(lang);
        setHeader("Paramètres des cookies");
        setSubHeader(
          "UrbanWebMap utilise des cookies pour stocker des informations sur votre visite et pour fournir des fonctionnalités étendues"
        );
        setRequiredCookies("Cookies requis");
        setTableHeaderName("Nom");
        setTableHeaderExpires("Expire");
        setUrbanWebMapCookieDescription(
          "Ce site utilise des cookies pour stocker des informations sur votre visite et les cookies que vous acceptez"
        );
        setYoutubeCookieDescription(
          `Le site Web peut inclure des vidéos YouTube. Si vous n'acceptez pas ce cookie, les vidéos ne seront pas affichées`
        );
        setGoogleCookieDescription(
          `Le site Web peut inclure ReCAPTCHA de www.google.com. Ces cookies sont nécessaires pour la vérification des e-mails. Si vous n'acceptez pas ce cookie, vous ne pourrez pas envoyer de questions`
        );
        setContinueButton("Enregistrer");
        break;
    }
  };

  const onYoutubeAccordionOpen = () => {
    setGoogleAccordionOpen(false);
    setRequiredAccordionOpen(false);
  };

  const onGoogleAccordionOpen = () => {
    setYoutubeAccordionOpen(false);
    setRequiredAccordionOpen(false);
  };

  const onRequiredAccordionOpen = () => {
    setGoogleAccordionOpen(false);
    setYoutubeAccordionOpen(false);
  };

  return (
    <div className="cookie-container">
      <StyledPaper elevation={10}>
        <div className="cookie-header">
          <h1>{header}</h1>
          <ToggleButtonGroup
            className="cookie-buttonGroup"
            value={lang}
            exclusive
          >
            <ToggleButton
              onClick={(event: any) =>
                changeLanguage(event, "EN")
              }
              value="EN"
              classes={{
                root: toggleClass.root,
                selected: toggleClass.selected,
              }}
            >
              EN
            </ToggleButton>
            <ToggleButton
              onClick={(event: any) =>
                changeLanguage(event, "NL")
              }
              value="NL"
              classes={{
                root: toggleClass.root,
                selected: toggleClass.selected,
              }}
            >
              NL
            </ToggleButton>
            <ToggleButton
              onClick={(event: any) =>
                changeLanguage(event, "FR")
              }
              value="FR"
              classes={{
                root: toggleClass.root,
                selected: toggleClass.selected,
              }}
            >
              FR
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        <p>{subHeader}</p>
        <div className="cookies-header">
          <h3 style={{ marginRight: "8px" }}>{requiredCookies}</h3>
          <Switch
            checked={true}
            name="required"
            classes={{
              switchBase: switchClass.switchBase,
              colorSecondary: switchClass.disabled,
              root: switchClass.root,
              checked: switchClass.checked,
              track: switchClass.track,
              thumb: switchClass.thumb,
            }}
            disabled={true}
          />
        </div>
        <Accordion
          onChange={(e, expanded) => {
            setRequiredAccordionOpen(!requiredAccordionOpen);
            if (expanded) {
              onRequiredAccordionOpen();
            }
          }}
          expanded={requiredAccordionOpen}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <p>{urbanWebMapCookieDescription}</p>
          </AccordionSummary>
          <AccordionDetails>
            <table style={{ textAlign: "center" }}>
              <thead>
                <tr>
                  <th>{tableHeaderName}</th>
                  <th>{tableHeaderExpires}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>OptanonConsent</td>
                  <td>1 year</td>
                </tr>
              </tbody>
            </table>
          </AccordionDetails>
        </Accordion>

        <div className="cookies-header">
          <h3 style={{ marginRight: "8px" }}>Youtube cookies</h3>
          <Switch
            checked={props.youtubeCookie}
            name="youtube"
            classes={{
              switchBase: switchClass.switchBase,
              colorSecondary: switchClass.colorSecondary,
              root: switchClass.root,
              checked: switchClass.checked,
              track: switchClass.track,
              thumb: switchClass.thumb,
            }}
            onChange={props.handleSwitchChange}
          />
        </div>
        <Accordion
          onChange={(e, expanded) => {
            setYoutubeAccordionOpen(!youtubeAccordionOpen);
            if (expanded) {
              onYoutubeAccordionOpen();
            }
          }}
          expanded={youtubeAccordionOpen}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <p>{youtubeCookieDescription}</p>
          </AccordionSummary>
          <AccordionDetails>
            <table style={{ textAlign: "center" }}>
              <thead>
                <tr>
                  <th>{tableHeaderName}</th>
                  <th>{tableHeaderExpires}</th>
                </tr>
              </thead>
              <tbody>
              <tr>
                <td>_SECURE-3PSIDCC</td>
                <td>1 year</td>
              </tr>
              <tr>
                <td>_Secure-3PSID</td>
                <td>2 years</td>
              </tr>
              <tr>
                <td>_Secure-1PSID</td>
                <td>2 years</td>
              </tr>
              <tr>
                <td>SID</td>
                <td>2 years</td>
              </tr>
              <tr>
                <td>SIDCC</td>
                <td>1 year</td>
              </tr>
              <tr>
                <td>_Secure-3PAPISID</td>
                <td>2 years</td>
              </tr>
              <tr>
                <td>SSID</td>
                <td>2 years</td>
              </tr>
              <tr>
                <td>_Secure-1PAPISID</td>
                <td>2 years</td>
              </tr>
              <tr>
                <td>_GRECAPTCHA</td>
                <td>6 months</td>
              </tr>
              <tr>
                <td>HSID</td>
                <td>2 years</td>
              </tr>
              <tr>
                <td>SAPISID</td>
                <td>2 years</td>
              </tr>
              <tr>
                <td>APISID</td>
                <td>2 years</td>
              </tr>
              <tr>
                <td>1P_JAR</td>
                <td>1 month</td>
              </tr>
              <tr>
                <td>NID</td>
                <td>2 years</td>
              </tr>
              <tr>
                <td>OTZ</td>
                <td>1 month</td>
              </tr>
              <tr>
                <td>OGPC</td>
                <td>1 year</td>
              </tr>
              <tr>
                <td>SEARCH_SAMESITE</td>
                <td>6 months</td>
              </tr>
              <tr>
                <td>CONSENT</td>
                <td>17 years</td>
              </tr>
              </tbody>
            </table>
          </AccordionDetails>
        </Accordion>
        <div className="cookies-header">
          <h3 style={{ marginRight: "8px" }}>ReCAPTCHA cookies</h3>
          <Switch
            checked={props.googleCookie}
            name="google"
            classes={{
              switchBase: switchClass.switchBase,
              colorSecondary: switchClass.colorSecondary,
              root: switchClass.root,
              checked: switchClass.checked,
              track: switchClass.track,
              thumb: switchClass.thumb,
            }}
            onChange={props.handleSwitchChange}
          />
        </div>
        <Accordion
          onChange={(e, expanded) => {
            setGoogleAccordionOpen(!googleAccordionOpen);
            if (expanded) {
              onGoogleAccordionOpen();
            }
          }}
          expanded={googleAccordionOpen}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <p>{googleCookieDescription}</p>
          </AccordionSummary>
          <AccordionDetails>
            <table style={{ textAlign: "center" }}>
              <thead>
                <tr>
                  <th>{tableHeaderName}</th>
                  <th>{tableHeaderExpires}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>SIDCC</td>
                  <td>1 year</td>
                </tr>
                <tr>
                  <td>_Secure-1PSID</td>
                  <td>2 years</td>
                </tr>
                <tr>
                  <td>SID</td>
                  <td>2 years</td>
                </tr>
                <tr>
                  <td>_Secure-3PAPISID</td>
                  <td>2 years</td>
                </tr>
                <tr>
                  <td>_Secure-3PSID</td>
                  <td>2 years</td>
                </tr>
                <tr>
                  <td>SSID</td>
                  <td>2 years</td>
                </tr>
                <tr>
                  <td>SAPISID</td>
                  <td>2 years</td>
                </tr>
                <tr>
                  <td>_Secure-1PSID</td>
                  <td>2 years</td>
                </tr>
                <tr>
                  <td>_Secure-1PAPISID</td>
                  <td>2 years</td>
                </tr>
                <tr>
                  <td>HSID</td>
                  <td>2 years</td>
                </tr>
                <tr>
                  <td>APISID</td>
                  <td>2 years</td>
                </tr>
                <tr>
                  <td>wide</td>
                  <td>Session</td>
                </tr>
                <tr>
                  <td>YSC</td>
                  <td>2 years</td>
                </tr>
                <tr>
                  <td>CONSENT</td>
                  <td>17 years</td>
                </tr>
                <tr>
                  <td>LOGIN_INFO</td>
                  <td>2 years</td>
                </tr>
                <tr>
                  <td>PREF</td>
                  <td>2 years</td>
                </tr>
                <tr>
                  <td>VISITOR_INFO1_LIVE</td>
                  <td>2 years</td>
                </tr>
              </tbody>
            </table>
          </AccordionDetails>
        </Accordion>
        <div className="cookie-button">
          <Button
            variant="contained"
            classes={{ root: buttonClass.root }}
            onClick={props.handleButtonCookie}
          >
            {continueButton}
          </Button>
        </div>
      </StyledPaper>
    </div>
  );
}

export default CookieConsent;
