
import React, { useState } from 'react';

import { renderToStaticMarkup } from "react-dom/server";

import {
    MapContainer,
    TileLayer,
    Marker,
    Popup,
    Polygon,
    ZoomControl,
    MapContainerProps as LeafletMapProps,
  } from "react-leaflet";
  import L, {
    LatLngExpression,
    divIcon,
  } from "leaflet";
  import { ReactComponent as PanoramicIcon } from "../icons/vrpano_black_24dp.svg";

import { Info, Image, LocationOn, Help, YouTube } from "@mui/icons-material";

import { ReactComponent as LinkIcon } from "../icons/linkicon.svg";
import { MapModeToggler } from "./togglers";

import SearchBar from "./searchBar";
import SetView from "./setView";
import GetMapInfo from "./getMapInfo";
import styled from "styled-components";

interface MapProps extends LeafletMapProps {
    latitude: number;
    longitude: number;
    zoom: number;
    minZoom: number;
    maxZoom: number;
    maxNativeZoomOSM: number;
    maxNativeZoomSatellite: number;
    phases: any[];
    bounds: any;
    project_description: string;
    currentphase: any;
    markerClicked: (poi: any) => void;
    host: string;
    mediaStorage: any;
    showFocuszones: boolean;
    changeCurrentActiveFocuszoneData: ( description: string,
        image: string) => void;
    setCurrentActiveFocuszoneQandA: (questions: any,
        description?: any,
        image?: any) => void;
    isSatteliteButtonVisible: boolean;
    isSearchButtonVisible: boolean;
    isMapBlank: boolean;
    projectName: string;
    tileLayerBounds: any;
    questionsStyledProps: any;
    translations: any;
}


const SearchBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  width: 100%;
  top: 12px;
  right: 8px;
`;

const MapModeTogglerWrapper = styled.div`
  position: absolute;
  left: 85px;
  bottom: 25px;
  @media (max-width: 599px) {
    left: 67px;
  }
`;

// const useStyles: Styles<Theme, {}, string> = (theme: Theme) => ({
//   overlayButton: {
//     color: "black",
//     backgroundColor: "white",
//     marginRight: "8px",
//     marginTop: "8px",
//     zIndex: 3,
//     "&:hover": {
//       backgroundColor: "lightgrey",
//     },
//   },
//   searchButton: {
//     zIndex: 3,
//   },
// });

const Map: React.FC<MapProps> = (props) => {
    const [latitude] = useState<number>(props.latitude);
    const [longitude] = useState<number>(props.longitude);
    const [center, setCenter] = useState<LatLngExpression>(L.latLng(latitude, longitude));
    const [zoom, setZoom] = useState<number>(props.zoom);
    const [changeView, setChangeView] = useState<boolean>(false);
    const [isSatelliteView, setIsSatelliteView] = useState<boolean>(true);
    const [isSearchMarker, ] = useState<boolean>(false);
    const [projectName] = useState<string>(props.projectName);
    const [url, setUrl] = useState<string>("");

    let toggleSatellite = () => {
        setIsSatelliteView(!isSatelliteView);
        setUrl( isSatelliteView
            ? "https://services.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png"
            : "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        );
      };

      const bounds = props.bounds
      ? L.latLngBounds(
          [props.bounds.SWLatitude, props.bounds.SWLongitude],
          [props.bounds.NELatitude, props.bounds.NELongitude]
        )
      : undefined;

      const changeCenter = (longitude: number, latitude: number) => {
        setCenter(L.latLng(longitude, latitude));
        setZoom(props.maxZoom);
        setChangeView(true);
        setTimeout(() => setChangeView(false), 100);
      };

      let chooseIcon = (poi: any) => {
        let chosenIcon;
        switch (poi.Marker) {
          case "url":
            const urlMarkup = renderToStaticMarkup(
              <LinkIcon
                style={{ fill: `rgb(${poi.color})` }}
                className="markerButtons"
              />
            );
            const url = divIcon({ html: urlMarkup, iconSize: [26, 26] });
            chosenIcon = url;
            break;
          case "info":
            const infoMarkup = renderToStaticMarkup(
              <Info
                style={{ color: `rgb(${poi.color})` }}
                className="markerButtons"
              />
            );
            const info = divIcon({ html: infoMarkup, iconSize: [20, 20] });
            chosenIcon = info;
            break;
          case "question":
            const questionMarkup = renderToStaticMarkup(
              <Help
                style={{ color: `rgb(${poi.color})` }}
                className="markerButtons"
              />
            );
            const question = divIcon({ html: questionMarkup, iconSize: [20, 25] });
            chosenIcon = question;
            break;
          case "image":
            const imageMarkup = renderToStaticMarkup(
              <Image
                style={{ color: `rgb(${poi.color})` }}
                className="markerButtons"
              />
            );
            const image = divIcon({ html: imageMarkup, iconSize: [20, 20] });
            chosenIcon = image;
            break;
          case "image360":
            const panoramaMarkup = renderToStaticMarkup(
              <PanoramicIcon
                style={{ fill: `rgb(${poi.color})` }}
                className="markerButtons"
              />
            );
            const panorama = divIcon({ html: panoramaMarkup, iconSize: [28, 17] });
            chosenIcon = panorama;
            break;
          case "video":
            const videoMarkup = renderToStaticMarkup(
              <YouTube
                style={{ color: `rgb(${poi.color})` }}
                className="markerButtons"
              />
            );
            const video = divIcon({ html: videoMarkup, iconSize: [20, 20] });
            chosenIcon = video;
        }
    
        return chosenIcon;
      };

      const locationMarkup = renderToStaticMarkup(
        <LocationOn style={{ color: `black` }} className="markerButtons" />
      );
      
      const location = divIcon({ html: locationMarkup, iconSize: [20, 20] });

    return (
      <div>
              {props.isSearchButtonVisible && (
                <SearchBarWrapper>
                    <SearchBar
                        bounds={props.bounds}
                        changeCenter={(longitude, latitude) => 
                            changeCenter(latitude, longitude)
                        }
                    />
                </SearchBarWrapper>
                )} 
                {props.isSatteliteButtonVisible && (
                    <MapModeTogglerWrapper 
                        className="appBar-mapMode"
                        >
                        <MapModeToggler
                            checked={!isSatelliteView}
                            callback={ () => toggleSatellite() }
                            mainAccent={props.questionsStyledProps.mainAccent}
                            translations={props.translations}
                        />
                    </MapModeTogglerWrapper>
                )}
                <MapContainer
                    className='map'
                    center={center}
                    zoom={zoom}
                    zoomControl={false}
                    minZoom={props.minZoom}
                    maxZoom={props.maxZoom}
                    scrollWheelZoom={true}
                    maxBounds={bounds}
                    maxBoundsViscosity={1.0}
                    style={{ height: "100vh", width: "100%" }}
                >
                    <ZoomControl position="bottomright" />
                    {!props.isMapBlank && (
                        <div>
                            {isSatelliteView ? (
                                <TileLayer
                                    url="https://services.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png"
                                    attribution='Powered by <a target=_blank href="https://www.esri.com">Esri</a> | <a target=�_blank� href="https://tractebel-engie.com/en">By Tractebel ENGIE</a> | <a target=�_blank� href="/termsofuse">Terms of use</a> | <a target=�_blank� href="/privacypolicy">Privacy policy</a>'
                                    maxNativeZoom={props.maxNativeZoomSatellite}
                                    maxZoom={props.maxZoom}
                                />
                            ) : (
                                <TileLayer
                                    key={url}
                                    attribution='&copy; <a target=_blank href="http://osm.org/copyright">OpenStreetMap</a> contributors | <a target=�_blank� href="https://tractebel-engie.com/en">By Tractebel ENGIE</a> | <a target=�_blank� href="/termsofuse">Terms of use</a> | <a target=�_blank� href="/privacypolicy">Privacy policy</a>'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    maxNativeZoom={props.maxNativeZoomOSM}
                                    maxZoom={props.maxZoom}
                                />
                            )}
                        </div>
                    )}
                    {props.isMapBlank && (
                        <TileLayer
                            url={""}
                            attribution='<a target=�_blank� href="https://tractebel-engie.com/en">By Tractebel ENGIE</a> | <a target=�_blank� href="/termsofuse">Terms of use</a> | <a target=�_blank� href="/privacypolicy">Privacy policy</a>'
                        />
                    )}
                    {props.tileLayerBounds !== undefined && (
                        <TileLayer
                            key={
                                props.currentphase.phase_name + props.tileLayerBounds
                            }
                            url={`${props.mediaStorage}/tiles/${projectName}/${props.currentphase.phase_name}/{z}/{x}/{y}.png`}
                            tms={true}
                            opacity={1}
                            attribution=""
                            minNativeZoom={props.minZoom}
                            maxNativeZoom={props.maxZoom}
                            maxZoom={props.maxZoom}
                            zIndex={2000}
                            bounds={props.tileLayerBounds}
                        />
                    )}
                    {props.currentphase && props.currentphase.POIS.map((poi: any, index: number) => {
                        return (
                            <Marker
                                key={index}
                                position={[poi.latitude, poi.longitude]}
                                icon={chooseIcon(poi)}
                                eventHandlers={{
                                click: (e) => {
                                    props.markerClicked(poi);
                                },
                                mouseover: (e) => {
                                    e.target.openPopup();
                                },
                                mouseout: (e) => {
                                    e.target.closePopup();
                                },
                                }}
                            >
                                <Popup closeButton={false}>{poi.description_short}</Popup>
                            </Marker>

                          );
                        })}
                        {isSearchMarker && (
                            <Marker position={center} icon={location} />
                        )}
                        {props.currentphase && 
                        props.currentphase.FocusZones && 
                        props.showFocuszones && 
                        props.currentphase.FocusZones.map(
                            (data: any, index: number) => {
                                let polygonVertices: L.LatLng[] = [];
                                data.Polygon.coordinates.forEach((vertex: any, vertexIndex: number) => {
                                    polygonVertices.push(L.latLng(vertex[1], vertex[0]));
                                });
                                return (
                                    <Polygon
                                        key={index}
                                        className="focuszone"
                                        positions={polygonVertices}
                                        pathOptions={{ color: data.Polygon.RGBA }}
                                        eventHandlers={{
                                        click: () => {
                                            const Image = data.Image
                                            ? data.Image.formats.small
                                                ? data.Image.formats.small.url
                                                : data.Image.formats.thumbnail.url
                                            : "";
                                            props.setCurrentActiveFocuszoneQandA(
                                            data.id,
                                            data.Description,
                                            Image
                                            );
                                        },
                                        }}
                                    />
                                )
                            }
                        )}
                        {changeView && (
                            <SetView center={center} zoom={zoom} />
                        )}
                        <GetMapInfo />
                    </MapContainer>
            </div>
        );
    };

export default Map;