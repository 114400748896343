import axios from "axios";

export async function GetSites(host: string) {
  const response = axios.get(`${host}/sites_index.json`)
    .then(response => response.data)
    .then(json => {
      return json;
    })
  .catch(error => console.log(error))

  return response;
}

export async function GetCurrentSite (host: string, currentSite: string) {
  const response = axios.get(`${host}/${currentSite.toLowerCase()}.json`)
    .then(response => response.data)
    .then(json => {
      return json;
    })
    .catch(error => {
      console.log(error);
    });

  return response;
}