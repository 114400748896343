import { Theme } from "@mui/material/styles";
import {makeStyles, createStyles} from "@mui/styles";
import {Close, Home, Menu} from "@mui/icons-material";
import {AppBar, Button, Drawer, IconButton} from "@mui/material";
import clsx from "clsx";
import React from "react";

import CookieIcon from "../icons/cookie.svg";
import { ReactComponent as HelpIcon } from "../icons/help.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      margin: "auto",
      justifyContent: "space-between",
      alignItems: "flex-end",
      '& $menuButton': {
        marginRight: "8px",
        padding: "12px",
        [theme.breakpoints.down("xs")]: {
          marginRight: "0px",
        },
      },
      '& $hide': {
        display: "none",
      },
      '& $drawer': {
        flexShrink: 0,
        whiteSpace: "nowrap",
      },
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: (props: any) => props.drawerWidth,
      width: (props: any) => `calc(100% - ${props.drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: "8px",
      [theme.breakpoints.down("xs")]: {
        marginRight: "0px",
      },
    },
    hide: {
      display: "none",
    },
    drawer: {
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      width: (props: any) => props.drawerWidth,
      [theme.breakpoints.down("xs")]: {
        width: "100% !important",
      },
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      zIndex: 1999,
      backgroundColor: (props: any) =>
        props.questionsStyledProps.mainBackground,
      color: (props: any) => props.questionsStyledProps.mainBackgroundFontColor,
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(7),
      zIndex: 1999,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
      height: "100%",
      backgroundColor: (props: any) =>
        props.questionsStyledProps.mainBackground,
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      minHeight: "48px !important",
    },
    content: {
      width: "80%",
      margin: "auto",
      flexGrow: 1,
      whiteSpace: "normal",
    },
  })
);

export default function MiniDrawer(props: any) {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: props.isDrawerOpen,
        })}
      ></AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: props.isDrawerOpen,
          [classes.drawerClose]: !props.isDrawerOpen,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: props.isDrawerOpen,
            [classes.drawerClose]: !props.isDrawerOpen,
          }),
        }}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={props.toggleDrawer}
          edge="start"
          className={clsx(classes.menuButton, {
            [classes.hide]: props.isDrawerOpen,
          })}
        >
          <Menu
            style={{
              color: props.questionsStyledProps.mainBackgroundFontColor,
            }}
          />
        </IconButton>
        <div className={classes.toolbar}>
          {props.goBack !== undefined && props.isDrawerOpen && (
            <IconButton
              onClick={() => {
                props.goBack();
              }}
            >
              <Home
                style={{
                  color: props.questionsStyledProps.mainBackgroundFontColor,
                }}
              />
            </IconButton>
          )}
          {props.isDrawerOpen && (
            <IconButton
              onClick={() => {
                props.closeDrawer();
              }}
            >
              <Close
                style={{
                  color: props.questionsStyledProps.mainBackgroundFontColor,
                }}
              />
            </IconButton>
          )}
        </div>
        <div
          className={clsx(classes.content, {
            [classes.hide]: !props.isDrawerOpen,
          })}
        >
          {props.children}
        </div>
        <div className="drawer-icons-container">
          <Button onClick={props.openTour}>
            <HelpIcon
              style={{
                fill: props.questionsStyledProps.mainBackgroundFontColor,
              }}
            />
          </Button>
          <Button onClick={props.openCookie}>
            <img
              src={CookieIcon}
              style={{
                filter:
                  props.questionsStyledProps.mainBackgroundFontColor ===
                  "#ffffff"
                    ? "invert(1)"
                    : "invert(0)",
              }}
              alt="cookie"
            />
          </Button>
        </div>
      </Drawer>
    </div>
  );
}
