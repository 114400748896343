import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CircularProgress, Grid } from "@mui/material";
import { GetCurrentSite } from "../api/Api";

import Home from "./home";

type mapValue = {
  mapValue: string;
};

interface Props {
  baseUrl: string;
  firstVisit: boolean;
  host: string;
  mediaStorage: string;
  url: any;
}

const MapRedirect = (props: Props) => {
  const { mapValue } = useParams<mapValue>();

  const [site, setSite] = useState(null);
  const [loadingFlag, setLoadingFlag] = useState(false);

  useEffect(() => {
    GetCurrentSite(props.baseUrl, mapValue!)
      .then((data) => {
        if (data === undefined) {
          window.location.replace("/UrbanWebMap");
        } else {
          setSite(data);
          setLoadingFlag(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line
  }, []);

  if (loadingFlag && site != null) {
    return (
      <Home
        site={site}
        firstVisit={localStorage.noFirstVisit}
        host={props.host}
        mediaStorage={props.mediaStorage}
        url={props.url}
      />
    );
  } else {
    return (
      <Grid
        container
        spacing={0}
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        <CircularProgress />
      </Grid>
    );
  }
};

export default MapRedirect;
