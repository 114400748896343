import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

export type UrlParams = {
  menu?: string;
  fzmenu?: string;
  fzmenuid?: string;
  fz?: string;
  phase?: string;
  qanda?: string;
  cfzone?: string;
  zoom?: string;
  lat?: string;
  lng?: string;
  info?: string;
  question?: string;
  image?: string;
  imagep?: string;
  pano?: string;
  video?: string;
};

export function useSearchParamsUpdater() {
  const navigate = useNavigate();
  const location = useLocation();

  const [urlQueue, setUrlQueue] = useState<Partial<UrlParams>[]>([]);

  function updateSearchParams(updates: Partial<UrlParams>) {
    setUrlQueue((prevQueue: Partial<UrlParams>[]) => [ ...prevQueue, updates ]);
  }

  useEffect(() => {
    if (urlQueue.length > 0) {
      const updates = urlQueue[0];
      updaterSearchParams(updates);
      setUrlQueue((prevQueue: Partial<UrlParams>[]) => prevQueue.slice(1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlQueue]);

  function updaterSearchParams(updates: Partial<UrlParams>) {
    const searchParams = new URLSearchParams(location.search);
    Object.entries(updates).forEach(([key, value]) => {
      if (value === "") {
        searchParams.delete(key);
      } else if (value) {
        searchParams.set(key, value);
      }
    });
    navigate(`${location.pathname}?${searchParams.toString()}`);
  }

  return updateSearchParams;
}
