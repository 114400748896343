import React, { useEffect } from "react";
import {BrowserRouter as Router, Route, Routes, Navigate} from "react-router-dom";
import { TourProvider } from "@reactour/tour";
import "./App.css";
import Privacy from "./components/privacy";
import TermsOfUse from "./components/termsOfUse";
import { isDev, mediaHost, jsonHost } from "./environment";
import MapRedirect from "./components/mapRedirect";
import Home from "./components/home";

import urbanwebmap from "./api/urbanwebmap.json";

const host = isDev();
const mediaStorage = mediaHost();

declare global {
  interface Document {
    documentMode?: any;
  }
}

export const App = () => {
  const params = new URLSearchParams(window.location.search);
  const obj = {
    menu: params.get("menu") || "",
    focusZoneMenu: params.get("fzmenu") || "",
    focusZoneMenuId: parseInt(params.get("fzmenuid") || ""),
    showFocusZones: params.get("fz") || "",
    phase: parseInt(params.get("phase") || ""), //number
    qanda: parseInt(params.get("qanda") || ""),
    currentZone: parseInt(params.get("cfzone") || ""),
    zoom: parseInt(params.get("zoom") || ""),
    lat: params.get("lat") || "",
    lng: params.get("lng") || "",
    info: parseInt(params.get("info") || ""),
    question: parseInt(params.get("question") || ""),
    image: parseInt(params.get("image") || ""),
    imagePhase: parseInt(params.get("imagep") || ""),
    pano: parseInt(params.get("pano") || ""),
    video: parseInt(params.get("video") || ""),
  };
  const url = obj;

  useEffect(() => {
    checkForInternetExplorer();
  }, []);

  const checkForInternetExplorer = () => {
    return !!document.documentMode;
  };
  if (!localStorage.noFirstVisit) {
    localStorage.noFirstVisit = true;
  }

  const steps: any[] = []

  return (
      <TourProvider steps={steps}>
        <Router>
          <Routes>
            <Route
                path={"/termsofuse"}
                element={<TermsOfUse />}
            />
            <Route
                path={"/privacypolicy"}
                element={<Privacy />}
            />
            <Route
                path={"/UrbanWebMap"}
                element={<Home
                    site={urbanwebmap}
                    firstVisit={localStorage.noFirstVisit}
                    host={host}
                    mediaStorage={mediaStorage}
                    url={url}
                />
                }
            />
            <Route
                path={"/:mapValue"}
                element={<MapRedirect
                    baseUrl={jsonHost()}
                    firstVisit={localStorage.noFirstVisit}
                    host={host}
                    mediaStorage={mediaStorage}
                    url={url}
                />
                }
            />
            <Route path="/" element={<Navigate to="/UrbanWebMap" />}/>
          </Routes>
        </Router>
      </TourProvider>
  );
};

export default App;
