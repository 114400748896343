import {
  CardContent,
  Switch,
    Card,
    SwitchProps,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Map, FilterHdr} from '@mui/icons-material';

interface TogglerProps {
  checked: boolean;
  callback: (any: any) => void;
  mainAccent: string;
  translations: any;
}

interface IOSSwitchProps extends SwitchProps {
  rgbaColor: string;
}

const IOSSwitch = ({ rgbaColor, ...props }: IOSSwitchProps) => (
  <Switch
    {...props}
    sx={{
      '& .MuiSwitch-switchBase.Mui-checked': {
        color: rgbaColor, // Dynamic thumb color
        '&:hover': {
          backgroundColor: `${rgbaColor}20`, // Lighter hover state
        },
      },
      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: rgbaColor, // Dynamic track color
      },
      '& .MuiSwitch-track': {
        backgroundColor: 'rgba(0, 0, 0, .25)', // Default track color
      },
    }}
  />
);

const useStyles = makeStyles({
  root: {
    '&.toggle-root': {
      zIndex: 100,
      position: 'relative',
      marginTop: '16px',
      height: '70px',
      width: '130px',
    },
    '& .toggle-root-content': {
      padding: '6px !important',
      margin: 'auto',
    },
    '& .toggle-root-icons': {
      display: 'flex',
      alignItems: 'center',
    },
    '& .toggle-root-title': {
      textAlign: 'center',
    },
  },
  mapModeToggler: {
  },
  content: {
    padding: '6px !important',
    margin: 'auto',
  },
  icons: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    textAlign: 'center',
  },
});

export const FocuszoneToggler = (props: TogglerProps) => {
  const classes = useStyles();
  return (
    <div className="focuszoneSelector">
      <Card className={`${classes.root} toggle-root`}>
        <CardContent className={`${classes.content} toggle-root-content`}>
          <div className={`${classes.title} toggle-root-title`}>{props.translations.Focuszone_button}</div>
          <div>
            Off
            <IOSSwitch
              rgbaColor={props.mainAccent}
              checked={props.checked}
              onChange={props.callback}
            />
            On
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export const MapModeToggler = (props: TogglerProps) => {
  const classes = useStyles();
  let mainAccent = props.mainAccent;

  return (
    <Card className={[classes.root, classes.mapModeToggler, 'toggle-root'].join(' ')}>
      <CardContent className={`${classes.content} toggle-root-content`}>
        <div className={`${classes.title} toggle-root-title`}>{props.translations.Map_mode_button}</div>
        <div className={`${classes.icons} toggle-root-icons`}>
          <FilterHdr/>
          <IOSSwitch
            rgbaColor={mainAccent}
            checked={props.checked}
            onChange={props.callback}
          />
          <Map/>
        </div>
      </CardContent>
    </Card>
  );
};
