import { useState, useEffect, ChangeEvent } from 'react';

import Sidebar from './sidebar';

interface Props {
  poi: any;
  currentPhase: any;
  description: string;
  phases: any;
  isDrawerOpen: boolean;
  hasPhases: boolean;
  translations: any;
  projectName: string;
  sidebarWidth: number;
  googleCookie: boolean;
  questionsStyledProps: any;
  showFocuszones: boolean;
  toggleDrawer: () => void;
  closeDrawer: () => void;
  deleteContent: () => void;
  goBack: () => void;
  openDrawer: () => void;
  toggleFocuszones: (event: ChangeEvent<HTMLInputElement>) => void;
  phaseSelected: (phaseId: any) => void;
  openCookie: () => void;
  openTour: () => void;
  markerClicked: (poi: any, navigate: boolean) => void;
}

const Info = (props: Props) => {
  const [poi] = useState(props.poi);
  const [currentPhase, setCurrentPhase] = useState(props.currentPhase);
  const [description] = useState(props.description);
  const [isDrawerOpen, setIsDrawerOpen] = useState(props.isDrawerOpen);
  const [phases, setPhases] = useState<any>([]);
  const [showFocuszones, setShowFocuszones] = useState(props.showFocuszones);

  useEffect(() => {
    setIsDrawerOpen(true);
  }, []);

  useEffect(() => {
    setPhases([]);
    let phasesWithPOIs : any = [];
    let POIsWithOtherPhase : Array<String> = [];
    if (poi.other_phases !== null && poi.other_phases !== undefined && poi.other_phases !== "") {
      POIsWithOtherPhase.push(poi.poi_id);
      
      poi.other_phases.split(',').forEach((phaseId: any) => {
        POIsWithOtherPhase.push(phaseId);
      });

      for (let phase of props.phases) {
        phase.POIS.forEach((phasePoi: any) => {
         if (POIsWithOtherPhase.includes(phasePoi.poi_id)) {
           phasesWithPOIs.push(phase);
         }
         if (phasePoi.poi_id === poi.poi_id) {
           setCurrentPhase(phase);
         }
        });
      }
      setPhases(phasesWithPOIs);
    }
    // setDescription(poi.description);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poi]);

  const onPhaseSelected = (phaseId: number) => {
    if (phaseId === currentPhase.id) { return; }
    if (poi !== null || poi !== undefined) {
      const otherPhasesArray = poi.other_phases.split(',');
      props.phases.forEach((phase: any) => {
        if (phase.id === phaseId) {
          props.markerClicked(phase.POIS.find((poi: any) => otherPhasesArray.includes(poi.poi_id)), true);
          
        }
      })
      
    }
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
    props.toggleDrawer();
  };

  const closeDrawer = () => {
    if (isDrawerOpen === true) {
      setIsDrawerOpen(false);
      props.closeDrawer();
      setTimeout(() => {
        props.deleteContent();
      }, 200);
    }
  };

  const goBack = () => {
    props.goBack();
    props.deleteContent();
    props.openDrawer();
  };

  const toggleFocuszones = (event: ChangeEvent<HTMLInputElement>) => {
    setShowFocuszones(!showFocuszones);
    props.toggleFocuszones(event);
  };
  return (
    <Sidebar
      isDrawerOpen={isDrawerOpen}
      isQuestion={false}
      closeDrawer={closeDrawer}
      toggleDrawer={toggleDrawer}
      description={description}
      image={''}
      phases={phases}
      phaseSelected={onPhaseSelected}
      showFocuszones={showFocuszones}
      showFocuszonesCallback={toggleFocuszones}
      currentPhase={currentPhase}
      hasPhases={props.hasPhases}
      isMediaShown={true}
      translations={props.translations}
      projectName={props.projectName}
      sidebarWidth={props.sidebarWidth}
      googleCookie={props.googleCookie}
      questionsStyledProps={props.questionsStyledProps}
      goBack={goBack}
      openCookie={props.openCookie}
      openTour={props.openTour}
    />
  );
};

export default Info;
