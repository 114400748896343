import React from 'react';
import Sidebar from './sidebar';

import {IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Close } from '@mui/icons-material';

import { Pannellum } from 'pannellum-react';
import { useState } from 'react';
import { useEffect } from 'react';

interface Props {
  url: string;
  host: string;
  deleteContent: () => void;
  classes?: any;
  phases: any;
  poi: any;
  currentPhase: any;
  hasPhases: boolean;
  translations: any;
  projectName: string;
  sidebarWidth: number;
  phaseSelected: (phaseId: number) => void;
  isDrawerOpen: boolean;
  toggleDrawer: () => void;
  closeDrawer: () => void;
  googleCookie: boolean;
  questionsStyledProps: any;
  openTour: () => void;
  openCookie: () => void;
  markerClicked: (poi: any, navigate: boolean) => void;
}

interface State {
  poi: any;
  url: string;
  poiOtherPhases: any;
  currentPhase: any;
  phases: any;
  isDrawerOpen: boolean;
  description: string;
}

const useIconStyle = makeStyles({
  root: (props: any) => ({
    '&.MuiIconButton-colorPrimary': {
      backgroundColor: props.lighterAccent ? props.lighterAccent : 'lightgrey',
    },
    '&:hover, &.Mui-focusVisible': {
      backgroundColor: props.mainAccent ? props.mainAccent : 'grey',
    },
    width: '48px',
    top: '50px',
  }),
  phaseButtons: {
    marginLeft: '8px',
    top: '150px',
    zIndex: 1300,
  },
});

const useButtonStyle = makeStyles({
  root: {
    color: 'black',
  },
});


function Panorama(props: Props, state: State) {
  const iconClass = useIconStyle(props.questionsStyledProps);
  const buttonClass = useButtonStyle();

  const [url] = useState(props.host + '/' + props.url.split('/').pop());
  const [poi] = useState(props.poi);
  const [poiOtherPhases] = useState(
    props.poi.other_phases ? props.poi.other_phases.split(',') : ''
  );
  const [currentPhase] = useState(props.currentPhase);
  const [phases, setPhases] = useState(props.currentPhase);
  const [isDrawerOpen, setIsDrawerOpen] = useState(props.isDrawerOpen);
  const [description, setDescription] = useState('');

  useEffect(() => {
    for (let phase of props.phases) {
      for (let point of phase.POIS) {
        if (poiOtherPhases) {
          for (let otherPhase of poiOtherPhases) {
            if (point.poi_id === otherPhase) {
              let phaseArray = [];
              phaseArray.push(phases);
              phaseArray.push(phase);
              setPhases(phaseArray);
            }
          }
        }
      }
    }
      setDescription(poi.description);
    const pnlm = document.querySelector('.pnlm-container') as HTMLElement;
    pnlm.style.backgroundColor = props.questionsStyledProps.mainAccent;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPhaseSelected = (phaseId: number) => {
    if (phaseId === currentPhase.id) { return; }
    if (poi !== null || poi !== undefined) {
      const otherPhasesArray = poi.other_phases.split(',');
      props.phases.forEach((phase: any) => {
        if (phase.id === phaseId) {
          props.markerClicked(phase.POIS.find((poi: any) => otherPhasesArray.includes(poi.poi_id)), true);
        }
      })
    }
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
    props.toggleDrawer();
  };

  const closeDrawer = () => {
    if (isDrawerOpen === true) {
      setIsDrawerOpen(false);
      props.closeDrawer();
    }
  };

  const showFocusZones = () => {
    return false;
  };

  return (
    <React.Fragment>
      <Sidebar
        isDrawerOpen={isDrawerOpen}
        isQuestion={false}
        closeDrawer={closeDrawer}
        toggleDrawer={toggleDrawer}
        description={description}
        image={''}
        phases={phases}
        phaseSelected={onPhaseSelected}
        showFocuszones={false}
        showFocuszonesCallback={showFocusZones}
        currentPhase={currentPhase}
        hasPhases={props.hasPhases}
        isMediaShown={true}
        translations={props.translations}
        projectName={props.projectName}
        sidebarWidth={props.sidebarWidth}
        googleCookie={props.googleCookie}
        questionsStyledProps={props.questionsStyledProps}
        openCookie={props.openCookie}
        openTour={props.openTour}
      />
      <div className="panorama">
        <IconButton
          className={iconClass.root}
          color="primary"
          onClick={props.deleteContent}
        >
          <Close className={buttonClass.root} />
        </IconButton>
        <Pannellum
          width="100%"
          height="100vh"
          image={url + '?x-request=html'}
          pitch={10}
          yaw={180}
          hfov={110}
          autoLoad
          showZoomCtrl={false}
        />
      </div>
    </React.Fragment>
  );
}

export default Panorama;
