import { ToggleButton, ToggleButtonGroup} from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from 'clsx';

const useStylesButton = makeStyles({
  root: {
    '&:hover': {
      opacity: 1,
      backgroundColor: '#d4d4d4 !important',
    },
    '&$selected': {
      opacity: 1,
      fontWeight: 'bold',
      backgroundColor: (props: any) =>
        `${props.questionsStyledProps.mainAccent} !important`,
      color: (props: any) =>
        `${props.questionsStyledProps.mainAccentFontColor}`,
    },
    '&.button-class': {
      opacity: 1,
      zIndex: 1202,
      backgroundColor: 'white',
      position: 'relative',
      top: '12px',
      color: 'black',
      marginBottom: '4px',
      minWidth: '90px',
      textTransform: 'none',
      height: '40px',
      justifyContent: 'flex-start',
    }
  },
  selected: (props: any) => ({
    fontWeight: 'bold',
  }),
});

const sortPhases = (props: any) => {
  let phasesArray = props.phases;
  for (let i = 0; i < phasesArray.length; i++) {
    for (let j = i + 1; j < phasesArray.length; j++) {
      if (phasesArray[i].id > phasesArray[j].id) {
        const temp = phasesArray[i];
        phasesArray.splice(i, 1, phasesArray[j]);
        phasesArray.splice(j, 1, temp);
      }
    }
  }
};

const PhaseSelector = (props: any) => {
  sortPhases(props);
  const classes = useStylesButton(props);

  return (
    <ToggleButtonGroup
      value={props.currentPhase.id}
      exclusive
      orientation="vertical"
      className={clsx(props.className)}
    >
      {props.phases.length > 1 &&
        props.phases.map((phase: any, index: number) => (
          <ToggleButton
            key={index}
            classes={{ root: clsx(classes.root, 'button-class'), selected: classes.selected }}
            onClick={() => props.phaseSelected(phase.id)}
            value={phase.id}
          >
            {phase.phase_name}
          </ToggleButton>
        ))}
    </ToggleButtonGroup>
  );
};

export default PhaseSelector;
