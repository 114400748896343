import React from "react";
import styled from "styled-components";
import Recaptcha from "react-google-invisible-recaptcha";
import {
  Button,
  CircularProgress,
  TextField,
  IconButton,
  Theme,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import DOMPurify from "dompurify";
import { Styles } from "@mui/styles";
import { MailOutline, Clear } from "@mui/icons-material";

import { emailHost } from "../environment";

interface Props {
  topLabel?: string;
  project_name: string;
  classes: any;
  questionsStyledProps: any;
  currentQuestionPOI?: any;
  currentFocusZone?: any;
  sidebarWidth: any;
  translations: any;
  backButtonCallback?: any;
}
interface State {
  questionAskerName: string;
  questionAskerEmail: string;
  questionBody: string;
  showFocuszones: boolean;
  questionAskerNameLabel: string;
  questionAskerEmailLabel: string;
  questionBodyLabel: string;
  isNameFilled: boolean;
  isEmailFilled: boolean;
  isQuestionFilled: boolean;
  isSending: boolean;
  mailSuccessMessage: string;
}

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
`;
const TopLabel = styled.h1`
  font-size: 2rem;
`;
const SubmitButton = styled(Button)``;

const SubmitButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const FooterWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  height: 100%;
  font-size: small;
  color: rgba(0, 0, 0, 0.5);
  margin-top: 2%;
`;
const PrivacyPolicyNote = styled.div`
  width: 100%;
  text-align: center;
`;
const CloseButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: relative;
  left: 25px;
  top: 8px;
  border-radius: 50%;
`;

const useStyles: Styles<Theme, {}, string> = (theme: Theme) => ({
  inputField: {
    marginBottom: "15px",
  },
  customSubmitButton: {
    width: "55%",
    height: "60px",
    marginTop: "15px",
    fontWeight: "bold",
  },
});

const recaptchaRef = React.createRef<any>();
class AskForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      questionAskerName: "",
      questionAskerEmail: "",
      questionBody: "",
      showFocuszones: false,
      questionAskerNameLabel: props.translations.Mail_name,
      questionAskerEmailLabel: props.translations.Mail_address,
      questionBodyLabel: props.translations.Mail_text,
      isNameFilled: true,
      isEmailFilled: true,
      isQuestionFilled: true,
      isSending: false,
      mailSuccessMessage: "",
    };
  }

  handleQuestionAskerNameChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    this.setState({ questionAskerName: event.target.value });
    this.setState({ isNameFilled: true });
  }

  handleQuestionAskerEmailChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    this.setState({ questionAskerEmail: event.target.value });
    this.setState({ isEmailFilled: true });
  }

  handleQuestionBodyChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    this.setState({ questionBody: event.target.value });
    this.setState({ isQuestionFilled: true });
  }

  handleFocuszoneChecboxChange() {
    this.setState({ showFocuszones: !this.state.showFocuszones });
  }

  handleSubmitButtonClick = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    const isValidated = this.validateMail();
    if (isValidated === true) {
      const isAddressValidated = this.validateEmailAddress(
        this.state.questionAskerEmail
      );
      if (isAddressValidated) {
        await recaptchaRef.current?.execute();
      } else {
        this.setState({ isEmailFilled: false });
      }
    }
    return null;
  };

  handleSend = async () => {
    const url = emailHost();
    this.setState({ isSending: true });
    const token = await recaptchaRef.current.getResponse();
    const data = {
      askerMail: this.state.questionAskerEmail,
      askerQuestion: this.state.questionBody,
      askerName: this.state.questionAskerName,
      projectName: this.props.project_name,
      questionPOI: this.props.currentQuestionPOI
        ? this.props.currentQuestionPOI.description_short
        : this.props.currentFocusZone,
    };
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorizationToken: token,
      },
      body: JSON.stringify(data),
    });
    this.handleSendMessage(await response.json());
  };

  handleSendMessage = (body: any) => {
    this.setState({ isSending: false });
    if (body !== null) {
      this.setState({ questionAskerEmail: "" });
      this.setState({ questionAskerName: "" });
      this.setState({ questionBody: "" });
      this.setState({
        mailSuccessMessage: this.props.translations.Mail_confirmation,
      });
    } else {
      this.setState({
        mailSuccessMessage: this.props.translations.Mail_confirmation_fail,
      });
    }
  };

  validateMail = () => {
    let error = false;
    if (this.state.questionAskerEmail === "") {
      this.setState({ isEmailFilled: false });
      error = true;
    }
    if (this.state.questionAskerName === "") {
      this.setState({ isNameFilled: false });
      error = true;
    }
    if (this.state.questionBody === "") {
      this.setState({ isQuestionFilled: false });
      error = true;
    }
    if (error === true) {
      return false;
    } else {
      return true;
    }
  };

  validateEmailAddress = (email: string) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  render() {
    const { classes } = this.props;
    return (
      <MainWrapper>
        {this.props.backButtonCallback && (
          <CloseButtonWrapper>
            <IconButton onClick={this.props.backButtonCallback}>
              <Clear />
            </IconButton>
          </CloseButtonWrapper>
        )}

        <TopLabel>
          {this.props.topLabel !== undefined ? (
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(this.props.topLabel,
                  { ADD_TAGS: ["iframe"], ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'] }),
              }}
            ></div>
          ) : (
            this.props.translations.Ask_question_button
          )}
        </TopLabel>

        <TextField
          error={!this.state.isNameFilled}
          className={classes.inputField}
          variant="outlined"
          id="outlined-basic"
          label={this.state.questionAskerNameLabel}
          value={this.state.questionAskerName}
          onChange={(event) => this.handleQuestionAskerNameChange(event)}
        />
        <TextField
          error={!this.state.isEmailFilled}
          className={classes.inputField}
          variant="outlined"
          id="outlined-basic"
          label={this.state.questionAskerEmailLabel}
          value={this.state.questionAskerEmail}
          onChange={(event) => this.handleQuestionAskerEmailChange(event)}
        />

        <TextField
          error={!this.state.isQuestionFilled}
          className={classes.inputField}
          id="standard-multiline-flexible"
          label={this.state.questionBodyLabel}
          multiline
          variant="outlined"
          value={this.state.questionBody}
          onChange={(event) => this.handleQuestionBodyChange(event)}
          rows={8}
        />
        {this.state.isSending && <CircularProgress className="email-loading" />}
        <span className="email-message">{this.state.mailSuccessMessage}</span>
        <SubmitButtonWrapper>
          <SubmitButton
            className={classes.customSubmitButton}
            style={{
              color: this.props.questionsStyledProps.mainAccentFontColor,
              backgroundColor: this.props.questionsStyledProps.mainAccent,
            }}
            variant="contained"
            disabled={this.state.isSending}
            onClick={(event) => this.handleSubmitButtonClick(event)}
          >
            <MailOutline style={{ marginRight: "4px" }} />
            {this.props.translations.Send_mail_button}
          </SubmitButton>
        </SubmitButtonWrapper>
        <div className="captcha">
          <Recaptcha
            ref={recaptchaRef}
            badge={"bottomleft"}
            sitekey={"6LfAycUbAAAAAMHoWBUbJLQT6KwnIZ2iNvvNG0j6"}
            onResolved={this.handleSend}
          />
        </div>
        <FooterWrapper>
          <PrivacyPolicyNote>
            For more information on the collection and the processing of
            personal data, please consult our privacy policy available at{" "}
            <a
              href="/privacypolicy"
              style={{ wordWrap: "break-word" }}
              target="_blank"
            >
              privacy policy
            </a>
            .
          </PrivacyPolicyNote>
        </FooterWrapper>
      </MainWrapper>
    );
  }
}

export default withStyles(useStyles)(AskForm);
