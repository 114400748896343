import React, { useState, useEffect, ReactElement } from "react";

import Map from "./map";
import Questions from "./questions";
import Sidebar from "./sidebar";
import Image from "./image";
import Panorama from "./panorama";
import Video from "./video";
import Info from "./info";

import CookieConsent from "./cookieConsent";

import {useTour} from "@reactour/tour";
import {useSearchParamsUpdater} from "../services/urlService";
import getColourTheme from "../services/colourService";
import { useSearchParams } from "react-router-dom";
import { Button } from "@mui/material";

interface Props {
  firstVisit: boolean;
  host: string;
  mediaStorage: string;
  url: any;
  site: any;
}

interface Steps {
  selector: string;
  content: ReactElement;
  action?: any;
}

export const Home = (props: Props) => {
  let [searchParams] = useSearchParams();
  const updateSearchParams = useSearchParamsUpdater();
  const currentSite = props.site;
  let zoomFromUrl = currentSite.Location.zoom;
  let latitudeFromUrl;
  let longitudeFromUrl;
  let urlZoom = searchParams.get("zoom");
  if (urlZoom) {
    let intZoom = parseInt(urlZoom);
    if (
      intZoom >= currentSite?.Location.min_zoom &&
      intZoom <= currentSite.Location.max_zoom
    ) {
      zoomFromUrl = intZoom;
    }
  }

  let urlLat = searchParams.get("lat");
  let urlLng = searchParams.get("lng");
  if (urlLat && urlLng) {
    latitudeFromUrl = parseFloat(urlLat);
    longitudeFromUrl = parseFloat(urlLng);
  } else {
    latitudeFromUrl = currentSite.Location.latitude;
    longitudeFromUrl = currentSite.Location.longitude;
  }
  const [site, setSite] = useState(currentSite);
  const [longitude, setLongitude] = useState(longitudeFromUrl);
  const [latitude, setLatitude] = useState(latitudeFromUrl);
  const [bounds] = useState(currentSite.Bounds);
  const [zoom, setZoom] = useState(zoomFromUrl);
  const [minZoom] = useState(currentSite.Location.min_zoom);
  const [maxZoom] = useState(currentSite.Location.max_zoom);
  const [maxNativeZoomOSM] = useState(currentSite.Location.maxNativeZoomOSM);
  const [maxNativeZoomSatellite] = useState(
    currentSite.Location.maxNativeZoomSatellite
  );
  const [phases, setPhases] = useState<any[]>(currentSite.Phase);
  const [project_description] = useState(currentSite.project_description);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isQuestion, setIsQuestion] = useState(false);
  let urlPhase = 0;
  if (searchParams.has("phase")) {
    urlPhase = parseInt(searchParams.get("phase")!);
  }
  let selectedDescription = currentSite.project_description; 
  let selectedImage = "";
  const [description, setDescription] = useState(
    selectedDescription
  );
  const [image, setImage] = useState(selectedImage);
  const [currentPhase, setCurrentPhase] = useState(currentSite.Phase[urlPhase]);
  const [content, setContent] = useState(<div />);
  const [showFocuszones, setShowFocuszones] = useState(false);
  const [showQandA, setShowQandA] = useState(false);
  const [contentQandA, setContentQandA] = useState<null | number>(null);
  const [hasPhases, setHasPhases] = useState(false);
  const [showCookieConsent, setShowCookieConsent] = useState(false);
  const [projectName] = useState(currentSite.name);
  const [showQandAButton, setShowQandAButton] = useState(false);
  const [isMarkerClicked, setIsMarkerClicked] = useState(false);
  const [styledProps] = useState(getColourTheme(currentSite.ColourTheme));
  const [tileLayerBounds, setTileLayerBounds] = useState<any>();
  const [currentQuestionPOI, setCurrentQuestionPOI] = useState<any>();
  const [youtubeCookie, setYoutubeCookie] = useState(
    localStorage.youtubeCookie === "true"
  );
  const [googleCookie, setGoogleCookie] = useState(localStorage.googleCookie === "true");
  const [isFocuszone, setIsFocuszone] = useState(false);
  const [walkthroughPhase] = useState(
    currentSite.phase_id_walkthrough ? currentSite.phase_id_walkthrough : 0
  );
  const [wasCookieOpened, setWasCookieOpened] = useState(false);
  const [isTourOpen, setIsTourOpen] = useState(false);
  
  const { setIsOpen, setCurrentStep, setSteps } = useTour()

  const [key, setKey] = useState<number>(0);

    useEffect(() => {
      
      if (parseInt(searchParams.get("phase")!) !== currentPhase.id) {
        phases &&
          phases.forEach((phase: any) => {
            if (phase.id === parseInt(searchParams.get("phase")!)) {
              setCurrentPhase(phase);
              checkForBounds(phase);
            }
          });
      }
      
      if (searchParams.has("fz")) {
        if (searchParams.get("fz") === "1" && showFocuszones === false) {
          setShowFocuszones(true);
        } else if ((searchParams.get("fz") === "0" && showFocuszones === true) || (searchParams.get("fz") === null && showFocuszones === true)) {
          setShowFocuszones(false);
        }
      }

      if (
        searchParams.has("info") ||
        searchParams.has("question") ||
        searchParams.has("image") ||
        searchParams.has("pano") ||
        searchParams.has("video")
      ) {
        if (searchParams.has("info")) {
          markerClicked(findPOI(parseInt(searchParams.get("info")!)), false);
        } else if (searchParams.has("question")) {
          markerClicked(findPOI(parseInt(searchParams.get("question")!)), false);
        } else if (searchParams.has("image")) { 
          markerClicked(findPOI(parseInt(searchParams.get("image")!)), false);
        } else if (searchParams.has("pano")) {
          markerClicked(findPOI(parseInt(searchParams.get("pano")!)), false);
        } else if (searchParams.has("video")) {
          markerClicked(findPOI(parseInt(searchParams.get("video")!)), false);
        }

        if (searchParams.has("menu") && searchParams.get("menu") === "1") {
          setIsDrawerOpen(true);
        } else if (!searchParams.has("menu") || searchParams.get("menu") === "0"){
          setIsDrawerOpen(false);
        }
      } else {
        if (searchParams.get("menu") === "1") {
          setIsDrawerOpen(true);
        } else if (!searchParams.has("menu") || searchParams.get("menu") === "0"){
          setContent(<div />);
          setIsDrawerOpen(false);
          setIsMarkerClicked(false);
        }
      }

      if (searchParams.has("fz")) {
        if (searchParams.has("fzmenuid") && searchParams.get("fzmenu") === "1"){
          let focusZoneDescription: string = "";
          currentPhase.FocusZones.forEach((zone: any) => {
            if (zone.id === parseInt(searchParams.get("fzmenuid")!)) {
              focusZoneDescription = zone.Description;
              setShowQandA(false);
              setContentQandA(parseInt(searchParams.get("fzmenuid")!));
              setShowQandAButton(currentSite.QA_enabled);
              setDescription(focusZoneDescription);
              setImage(props.host + image);
              setIsDrawerOpen(true);
            }
          });
        } else if (searchParams.has("fzmenuid") && searchParams.has("qanda") && searchParams.get("fzmenu") === "0"){
          let focusZoneDescription: string = "";
          let focusZoneImage: string = "";
          currentPhase.FocusZones.forEach((zone: any) => {
            if (zone.id === parseInt(searchParams.get("fzmenuid")!)) {
              focusZoneDescription = zone.Description;
              focusZoneImage = zone.Image;
              setShowQandA(true);
              setDescription(focusZoneDescription);
              setContentQandA(parseInt(searchParams.get("fzmenuid")!));
              setShowQandAButton(currentSite.QA_enabled);
              setImage(props.host + focusZoneImage);
              setIsDrawerOpen(true);
            }
          });
        } else if (!searchParams.has("fzmenuid")) {
          setShowQandA(false);
          setContentQandA(null);
          setIsDrawerOpen(false);
          setShowQandAButton(false);
          setDescription(site.project_description);
          setImage("");
          setIsFocuszone(false);
        }
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams]);


  useEffect(() => {
    prepareSteps();
    if (currentSite.ColourTheme.MainAccent.Red && currentSite.ColourTheme.MainAccent.Green && currentSite.ColourTheme.MainAccent.Blue) {
      document.documentElement.style.setProperty('--reactour-accent', `rgb(${currentSite.ColourTheme.MainAccent.Red}, ${currentSite.ColourTheme.MainAccent.Green}, ${currentSite.ColourTheme.MainAccent.Blue})`);
    } else {
      document.documentElement.style.setProperty('--reactour-accent', currentSite.ColourTheme.MainAccent.Hex);
    }
    
    // fictional use for isTourOpen
    console.log("Is Tour Open?", isTourOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const prepareSteps = () => {
    if (!currentSite.help_translations) {
      return [];
    }


    let poisExist: any;
    let poiLat: any;
    let poiLng: any;
    let focusZoneExist: any;

    phases.forEach((phase: any, index: number) => {
      if (phase.POIS.length > 0 && poisExist === undefined) {
        poisExist = index;
        phase.POIS.forEach((poi: any) => {
          if (poiLat === undefined && poiLng === undefined) {
            poiLat = poi.longitude
            poiLng = poi.latitude
          }
        });
      }
      if (phase.FocusZones.length > 0 && focusZoneExist === undefined) {
        focusZoneExist = index;
      }
    });
    const preparedSteps = [
      {
        selector: ".centralObject",
        content: currentSite.help_translations.Help_welcome,
        action: () => {
          setCurrentPhase(phases[walkthroughPhase]);
        }
      },
      {
        selector: ".centralObject",
        content: currentSite.help_translations.Help_zoom,
      },
      {
        selector: ".centralObject",
        content: currentSite.help_translations.Help_drag,
        action: () => {
          if (focusZoneExist !== undefined) 
          {
            setCurrentPhase(phases[focusZoneExist]);
            setShowFocuszones(true);
          };
        }
      },
    ] as Steps[];

    if (focusZoneExist !== undefined) {
      preparedSteps.push({
        selector: ".focuszoneSelector",
        content: currentSite.help_translations.Help_focuszones,
      });
    }
    if (document.querySelector(".MuiIconButton-edgeStart")) {
      preparedSteps.push({
        selector: ".MuiIconButton-edgeStart",
        content: currentSite.help_translations.Help_context_menu,
      });
    }
    if (phases && phases?.length > 1) {
      preparedSteps.push({
        selector: ".appBar-phaseSelector",
        content: currentSite.help_translations.Help_phases,
        action: () => {
          if (poisExist !== undefined) {
            setCurrentPhase(phases[poisExist]);
            setLatitude(poiLat);
            setLongitude(poiLng);
          }
        }
      });
    }
    if (poisExist !== undefined) {
      preparedSteps.push({
        selector: ".leaflet-marker-icon",
        content: currentSite.help_translations.Help_marker,
      });
    }

    let numberOfSteps: number = preparedSteps.length;

    preparedSteps[numberOfSteps - 1].content = <div><span>{preparedSteps[numberOfSteps - 1].content}</span><br/> <br/> <Button className="MuiButtonBase-root MuiButton-root MuiButton-contained"
        style={{
          textTransform: "none",
          backgroundColor: styledProps.mainAccent,
          color: styledProps.mainAccentFontColor,
        }} onClick={() => {
          setCurrentPhase(currentSite.Phase[0]);
          setIsOpen(false);
          setShowFocuszones(false);
          setIsTourOpen(false);
        }}>{currentSite.help_translations.Help_done}</Button></div>
    
    setSteps && setSteps(preparedSteps);
  };

  useEffect(() => {
    checkScreen();
    orderPhases().then(() => {
      setTitle(currentSite.Title_browser);

      localStorage.getItem("cookieConsent")
        ? setShowCookieConsent(false)
        : setShowCookieConsent(true); 
      if (searchParams.get("phase") === "" && currentPhase) {
        checkForBounds(currentPhase);
      }
    });
    prepareSteps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const orderPhases = async () => {
    let phaseArray = [];
    let i = 0;
    for (let phase of site?.Phase!) {
      phase.id = i;
      i++;
      phaseArray.push(phase);
    }
    setPhases(phaseArray);
    if (searchParams.has("phase")) {
      phaseSelected(parseInt(searchParams.get("phase")!));
      
    } else {
      phaseSelected(0);
      setCurrentPhase(site.Phase[0]);
    }
  };

  const findPOI = (poiId: number) => {
    let poi: any;
      for (let phase of site.Phase) {
        for (let point of phase.POIS) {
          if (point.id === poiId) {
            poi = point;
            break;
          }
        }
      }
    return poi;
  };

  const checkScreen = () => {
    if (window.innerWidth < 600) {
      site.sidebar_width = 250;
      setSite({ ...site, sidebar_width: 250 });
    }
  };

  const checkTour = () => {
    setIsTourOpen(Boolean(props.firstVisit));
  };

  const phaseSelected = (phaseId: number) => {
    phases &&
      phases.forEach((phase: any) => {
        if (phase.id === phaseId) {
          setCurrentPhase(phase);
          if (searchParams.get("phase") !== phaseId.toString()) {
            updateSearchParams({ phase: phaseId.toString() });
          }
          checkForBounds(phase);
        }
      });
  };

  const toggleDrawer = () => {
    isDrawerOpen
      ? updateSearchParams({ menu: "0" })
      : updateSearchParams({ menu: "1" });
    setIsDrawerOpen(!isDrawerOpen);
  };

  const toggleFocuszones = () => {
    showFocuszones
      ? updateSearchParams({ fz: "0" })
      : updateSearchParams({ fz: "1" });
    setShowFocuszones(!showFocuszones);
  };

  const closeDrawer = () => {
    setIsFocuszone(false);
    setIsDrawerOpen(false);
    updateSearchParams({ 
      fzmenu: "", 
      fzmenuid: "", 
      info: "",
      question: "",
      image: "",
      imagep: "",
      pano: "",
      video: "",
      menu: "" ,
    });
    setContentQandA(null);
    setCurrentQuestionPOI(null);
    setTimeout(() => {
      if (!isDrawerOpen) {
        setDescription(project_description);
        setImage("");
        setShowQandAButton(false);
        setIsQuestion(false);
      }
    }, 1000);
  };

  const goBack = () => {
    setHasPhases(false);
    setIsMarkerClicked(false);
    setDescription(project_description);
    setIsQuestion(false);
    setShowQandAButton(false);
    setContent(<div />);
    updateSearchParams({ 
      info: "", 
      question: "", 
      image: "",
      imagep: "",
      pano: "",
      video: "", 
      menu: "1" 
    });
    
  };

  const setCurrentActiveFocuszoneData = (
    description: string,
    image: string
  ) => {
    setIsQuestion(false);
    setDescription(description);
    setImage(image);
  };

  const setCurrentActiveFocuszoneQandA = (
    questionsId: any,
    description?: any,
    image?: any
  ) => {
    if (!isFocuszone) deleteContent();
    setIsQuestion(false);
    setIsFocuszone(true);
    if (contentQandA === questionsId && currentSite.QA_enabled) {
      setShowQandA(true);
      setDescription(description);
      updateSearchParams({ 
        fzmenu: "0", 
        fzmenuid: questionsId.toString(),
        qanda: questionsId.toString(), 
        phase: currentPhase.id.toString(),
      });
    } else {
      setContentQandA(questionsId);
      setShowQandAButton(currentSite.QA_enabled);
      setDescription(description);
      setImage(props.host + image);
      setIsDrawerOpen(true);
      updateSearchParams({ 
        fzmenu: "1",
        fzmenuid: questionsId.toString(),
        phase: currentPhase.id.toString(),
      });
    }
  };

  const openTour = () => {
    refreshPage(currentSite.Location.latitude, currentSite.Location.longitude, currentSite.Location.zoom);
    setIsOpen(true);
    setCurrentStep(0);
    setIsTourOpen(true);
    setIsDrawerOpen(false);
  };

  const refreshPage = (latitude: any, longitude: any, zoom: any) => {
    updateSearchParams({ lat: latitude.toString(), lng: longitude.toString(), zoom: zoom.toString() });
    setLatitude(latitude);
    setLongitude(longitude);
    setZoom(zoom);
    setKey(prevKey => prevKey + 1)
  };

  const handleOpenQandAButton = () => {
    setShowQandA(currentSite.QA_enabled);
  };

  const isQAshown = () => {
    return showQandA && currentSite.QA_enabled;
  };

  const closeQandA = () => {
    setShowQandA(false);
    setContentQandA(null);
    setIsDrawerOpen(false);
    setShowQandAButton(false);
    setDescription(site.project_description);
    setImage("");
    setIsFocuszone(false);
    updateSearchParams({ qanda: "", question: "", fzmenu: "", fzmenuid: "" });
  };

  const markerClicked = (poi: any, navigate: boolean) => {
    let hasPhases = checkIfImageHasPhases(poi);
    setIsMarkerClicked(true);
    let selectedPhaseId: string = "0";
    phases.forEach((phase: any) => {
      phase.POIS.forEach((point: any) => {
        if (point.id === poi.id) {
          selectedPhaseId = phase.id.toString();
          setCurrentPhase(phase);
        }
      });
    });


    switch (poi.Marker) {
      case "url":
        window.open(poi.url);
        break;
      case "info":
        setContent(
          <Info
            key={poi.id}
            poi={poi}
            currentPhase={currentPhase}
            description={poi.description}
            phases={phases}
            isDrawerOpen={isDrawerOpen}
            hasPhases={hasPhases}
            translations={site.help_translations}
            projectName={projectName}
            sidebarWidth={site.sidebar_width}
            googleCookie={googleCookie}
            questionsStyledProps={styledProps}
            showFocuszones={showFocuszones}
            toggleDrawer={toggleDrawer}
            closeDrawer={closeDrawer}
            toggleFocuszones={toggleFocuszones}
            deleteContent={deleteContent}
            goBack={goBack}
            phaseSelected={(phaseId) => phaseSelected(phaseId)}
            openDrawer={openDrawer}
            openTour={openTour}
            openCookie={openCookie}
            markerClicked={markerClicked}
          />
        );
        setIsMarkerClicked(true);
        setIsFocuszone(false);
        setImage("");
        setDescription(poi.description);
        setIsQuestion(false);
        if (navigate) {
          updateSearchParams({ 
            phase: selectedPhaseId,
            info: poi.id.toString(), 
            question: "",
            image: "",
            imagep: "",
            pano: "",
            video: "",
            menu: "1",
          });
        }
        break;
      case "question":
        setIsDrawerOpen(true);
        setIsQuestion(true);
        setDescription(poi.description);
        setImage("");
        setCurrentQuestionPOI(poi);
        if (navigate) {
          updateSearchParams({ 
            info: "", 
            question: poi.id.toString(),
            image: "",
            imagep: "",
            pano: "",
            video: "",
            phase: selectedPhaseId
          });
        }
        break;
      case "image":
        if (navigate) {
          updateSearchParams({ 
            phase: selectedPhaseId,
            info: "", 
            question: "",
            image: poi.id.toString(),
            imagep: selectedPhaseId,
            pano: "",
            video: "",
            menu: "0",
          });
        }
        setContent(
          <Image
            key={poi.id}
            host={props.mediaStorage}
            url={poi.img[0].url}
            deleteContent={deleteContent}
            poi={poi}
            hasPhases={hasPhases}
            phases={phases}
            currentPhase={currentPhase}
            translations={site.help_translations}
            projectName={projectName}
            sidebarWidth={site.sidebar_width}
            isDrawerOpen={isDrawerOpen}
            showFocuszones={showFocuszones}
            toggleDrawer={toggleDrawer}
            closeDrawer={closeDrawer}
            phaseSelected={phaseSelected}
            googleCookie={googleCookie}
            questionsStyledProps={styledProps}
            openTour={openTour}
            openCookie={openCookie}
            markerClicked={markerClicked}
          />
        );
        setIsMarkerClicked(true);
        break;
      case "image360":
        if (navigate) {
          updateSearchParams({ 
            info: "", 
            question: "",
            image: "",
            imagep: selectedPhaseId,
            pano: poi.id.toString(),
            video: "",
            phase: selectedPhaseId,
            menu: "0",
          });
        }
        
        setContent(
          <Panorama
            key={poi.id}
            host={props.mediaStorage}
            url={poi.img[0].url}
            deleteContent={deleteContent}
            phases={phases}
            poi={poi}
            currentPhase={currentPhase}
            hasPhases={hasPhases}
            translations={site.help_translations}
            projectName={projectName}
            sidebarWidth={site.sidebar_width}
            phaseSelected={phaseSelected}
            isDrawerOpen={isDrawerOpen}
            toggleDrawer={toggleDrawer}
            closeDrawer={closeDrawer}
            googleCookie={googleCookie}
            questionsStyledProps={styledProps}
            openTour={openTour}
            openCookie={openCookie}
            markerClicked={markerClicked}
          />
        );
        setIsMarkerClicked(true);
        break;
      case "video":
        if (navigate) {
          updateSearchParams({ 
            info: "", 
            question: "",
            image: "",
            imagep: selectedPhaseId,
            pano: "",
            video: poi.id.toString(),
            phase: selectedPhaseId,
            menu: "0",
          });
        }
        setContent(
          <Video
            key={poi.id}
            url={poi.url}
            deleteContent={deleteContent}
            phases={phases}
            poi={poi}
            currentPhase={currentPhase}
            hasPhases={hasPhases}
            translations={site.help_translations}
            projectName={projectName}
            sidebarWidth={site.sidebar_width}
            showFocuszones={showFocuszones}
            phaseSelected={phaseSelected}
            isDrawerOpen={isDrawerOpen}
            toggleDrawer={toggleDrawer}
            closeDrawer={closeDrawer}
            googleCookie={googleCookie}
            youtubeCookie={youtubeCookie}
            questionsStyledProps={styledProps}
            openCookie={openCookie}
            openTour={openTour}
            markerClicked={markerClicked}
          />
        );
        setIsMarkerClicked(true);
        break;
    }
  };

  const deleteContent = () => {
    isDrawerOpen && findAppBar();
    setIsFocuszone(false);
    setContent(<div />);
    setIsMarkerClicked(false);
    setIsQuestion(false);
    let zoomControls = document.querySelector<HTMLElement>(
      ".leaflet-control-zoom"
    );
    zoomControls?.classList.remove("zoom-control-custom-animation-open");
    zoomControls?.removeAttribute("style");
    // hasPhases && setHasPhases(false);
    clearMarkerParams();
  };

  const checkIfImageHasPhases = (poi: any) => {
    if (poi.other_phases !== "" && poi.other_phases !== null) {
      setHasPhases(true);
      return true;
    }
    return false;
  };

  const checkForBounds = (phase: any) => {
    if (phase && phase.tiles_bounds) {
      let tempArray = [];
      for (let coord of phase.tiles_bounds) {
        const coords = [coord[1], coord[0]];
        tempArray.push(coords);
      }
      setTileLayerBounds(tempArray);
    } else {
      setTileLayerBounds(undefined);
    }
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.name === "youtube" && setYoutubeCookie(!youtubeCookie);
    event.target.name === "google" && setGoogleCookie(!googleCookie);
  };

  const handleButtonCookie = () => {
    localStorage.youtubeCookie = youtubeCookie;
    localStorage.googleCookie = googleCookie;
    localStorage.setItem("cookieConsent", "true");
    setShowCookieConsent(false);
    !wasCookieOpened && checkTour();
    setWasCookieOpened(true);
  };

  const openCookie = () => {
    setShowCookieConsent(true);
  };

  const setTitle = (title: string) => {
    document.title = title;
  };

  const clearMarkerParams = () => {
    updateSearchParams({ 
      info: "", 
      question: "",
      image: "",
      imagep: "",
      pano: "",
      video: "",
      menu: "0",
    });
  };

  const openDrawer = () => {
    setIsDrawerOpen(true);
  };

  const findAppBar = () => {
    setTimeout(() => {
      const appBar = document.querySelectorAll(".appBar-animation");
      appBar[0]?.classList.remove("appBar-animation");
      addAnimation();
    }, 10);
  };

  const addAnimation = () => {
    setTimeout(() => {
      const appBar = document.querySelectorAll(".appBar");
      appBar[0]?.classList.add("appBar-animation");
    }, 10);
  };

  return (
        <div className="centralObject">
          <div
              style={{
                zIndex: 2000,
                width: "10px",
                height: "100%",
                position: "fixed",
                overflowY: "hidden",
                backgroundColor: styledProps.mainAccent,
              }}
          />
          {showCookieConsent && (
              <CookieConsent
                  styledProps={styledProps}
                  handleSwitchChange={(event) => {
                    handleSwitchChange(event);
                  }}
                  handleButtonCookie={handleButtonCookie}
                  youtubeCookie={youtubeCookie}
                  googleCookie={googleCookie}
              />
          )}
          {isQAshown() && (
              <Questions
                  key={contentQandA}
                  closeQandA={closeQandA}
                  phases={phases}
                  currentFocuszoneContentQandA={contentQandA}
                  description={description}
                  image={image}
                  questionsStyledProps={styledProps}
                  project_name={projectName}
                  sidebarWidth={site.sidebar_width}
                  googleCookie={googleCookie}
                  translations={site.help_translations}
                  openCookie={openCookie}
              />
          )}
          {content}
          {!isMarkerClicked && (
              <Sidebar
                  key={site.sidebar_width}
                  questionsStyledProps={styledProps}
                  isDrawerOpen={isDrawerOpen}
                  isQuestion={isQuestion}
                  closeDrawer={closeDrawer}
                  toggleDrawer={toggleDrawer}
                  description={description}
                  image={image}
                  openTour={openTour}
                  phases={phases}
                  phaseSelected={phaseSelected}
                  showFocuszones={showFocuszones}
                  showFocuszonesCallback={toggleFocuszones}
                  currentPhase={currentPhase}
                  hasPhases={hasPhases}
                  isMediaShown={false}
                  translations={site.help_translations}
                  projectName={projectName}
                  showQandAButton={showQandAButton}
                  handleQandAButton={handleOpenQandAButton}
                  sidebarWidth={site.sidebar_width}
                  isMarkerClicked={isMarkerClicked}
                  goBack={project_description === description ? undefined : goBack}
                  currentQuestionPOI={currentQuestionPOI}
                  googleCookie={googleCookie}
                  openCookie={openCookie}
              />
          )}
          {latitude !== null && longitude !== null && (
              <Map
                  key={key}
                  latitude={latitude}
                  longitude={longitude}
                  zoom={zoom}
                  minZoom={minZoom}
                  maxZoom={maxZoom}
                  maxNativeZoomOSM={maxNativeZoomOSM}
                  maxNativeZoomSatellite={maxNativeZoomSatellite}
                  phases={phases}
                  bounds={bounds}
                  project_description={project_description}
                  currentphase={currentPhase}
                  markerClicked={(poi: any) => markerClicked(poi, true)}
                  host={props.host}
                  mediaStorage={props.mediaStorage}
                  showFocuszones={showFocuszones}
                  changeCurrentActiveFocuszoneData={setCurrentActiveFocuszoneData}
                  setCurrentActiveFocuszoneQandA={setCurrentActiveFocuszoneQandA}
                  isSatteliteButtonVisible={currentSite.sattelite_button}
                  isSearchButtonVisible={site.search_button}
                  isMapBlank={site.blank_map}
                  projectName={site.name}
                  tileLayerBounds={tileLayerBounds}
                  questionsStyledProps={styledProps}
                  translations={currentSite.help_translations}
              />
          )}
        </div>
  );
};

export default Home;
